// 
// user.scss
// Use this to write your custom SCSS
//
.top-message {
	border-bottom: 1px solid #a9a9a9;
	padding: 0.75rem;
}

.nav-extra {
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: bold;
}
.nav-extra a.nav-link {
	color: white !important;
	text-decoration: none;
	display: inline;
}
@media screen and (min-width:768px) {
	.nav-extra {
		justify-content: space-between;
	}
}
@media screen and (max-width:500px) {
	.nav-extra {
		flex-direction: column;
	}
	.nav-extra > div:nth-of-type(2) {
		margin-top: 15px !important;
	}
	.nav-extra > div:nth-of-type(2) > div:nth-of-type(2) {
		margin-left: 1.5rem;
		margin-right: 1.5rem;
	}
}

.header-slides {
	max-height: 250px;
}

a.services.list-group-item {
	font-size: 0.85rem !important;
	border: 2px solid $primary !important;
}

.featured-post-thumb.first-item {
	width: 90px !important;
}

.block-title {
	text-align: center;
}
.block-title::after {
	left: 50% !important;
	margin-left: -1.125rem;
}

/********** .css-columns **********/
/* .css-columns is placed on the <form> element if embedding the submit button within the columns (odd-number of fields) and is placed on a <div> tag wrapping the fields when there is an even number of form elements and so the submit button is centered below the fields. */
.css-columns {
	width: 100% !important;
}
.two.css-columns {
	column-count: 2;
	column-width: 225px;
}
.three.css-columns {
	column-width: 255px;
	column-count: 3;
}
.four.css-columns {
	column-width: 290px;
	column-count: 4;
}

.form-control[type="submit"] {
	width: auto !important;
}
.form-group {
	page-break-inside: avoid;
}
.form-group label {
	padding-left: 0 !important;
	width: 100%;
}
.form-group textarea {
	width: 100%;
}

#id_wagtailcaptcha {
	display: flex;
	justify-content: center;
	margin-top: -1rem;
}

label.requiredField .asteriskField {
	color: red;
	margin-left: 3px;
}

label[for$="wagtailcaptcha"]::after {
	display: none;
}
label[for="id_captcha"] {
	display: none;
}

#hhsd_table tr th:nth-child(n+2), #hhsd_table tr td:nth-child(n+2) {
	text-align: center;
}
#hhsd_table td {
	vertical-align: middle;
}

#HHSD_info_modal p {
	overflow-wrap: break-word;
}

.btn-xs {
	padding: 0 0.75rem;
	line-height: 22px;
	height: 24px;
}

.page-title-heading {
	word-break: normal !important;
}

#payment_spinner, #spinner {
	position: fixed;
	top: calc(50vh - 4rem);
	left: calc(50vw - 4rem);
	width: 4rem;
	height: 4rem;
	z-index: 99999;
}

.errorlist {
	color: red;
}

.navbar-boxed .navbar-nav>.nav-item>.nav-link::before {
	top: auto !important;
	bottom: -1.0625rem !important;
}

.navbar-brand {
	width: auto;
	max-width: 250px;
}

.special-form {
	background-color: inherit;
}
.special-form input {
	padding: .75rem 0;
	color: #343b43;
	width: 100%;
    border: none;
    background-color: inherit;
    text-align: left;
    font-weight: 600;
}

.author-card-avatar img {
	background-color: #fefefe;
}
.author-card .author-card-cover {
	height: 75px;
}

.nav-tabs.nav-tabs-style-5 .nav-link {
	border-color: #33cb81;
}

.tag-wrapper {
	margin-top: 0.75rem;
}

label[for^="email_radio"] > .tag-wrapper > span {
	margin-left: 1rem;
	padding: 0.5rem 1rem;
}

label[for^="email_radio"] > .tag-wrapper > span.primary {
	color: white;
	background-color: $primary;
}

// Override Bootstrap thead-dark
.table .thead-dark th {
	background-color: $primary;
}

#child_care_payments_table th, #child_care_payments_table td {
	text-align: center;
}
#child_care_payments_table td {
	vertical-align: middle;
}
#child_care_payments_table .badge {
	font-size: 13px;
	padding: 5px 7px;
}
#child_care_registrants_table thead tr th,
#child_care_registrants_table tbody tr td {
	text-align: center;
}
#child_care_registrants_table thead tr th:nth-child(1),
#child_care_registrants_table tbody tr td:nth-child(1),
#child_care_registrants_table thead tr th:nth-child(4),
#child_care_registrants_table tbody tr td:nth-child(4) {
	text-align: left;
}
#child_care_registrants_table tbody td {
	vertical-align: middle;
	padding: 0.5rem 0.25rem;
}
#child_care_registrants_table {
	table-layout: fixed;
}
#child_care_registrants_table thead tr th:nth-child(1) {
	width: 20%;
}
#child_care_registrants_table thead tr th:nth-child(2) {
	width: 8%;
}
#child_care_registrants_table thead tr th:nth-child(3) {
	width: 15%;
}
#child_care_registrants_table thead tr th:nth-child(4) {
	width: 31%;
}
#child_care_registrants_table thead tr th:nth-child(5) {
	width: 21%;
}
#child_care_registrants_table thead tr th:nth-child(6) {
	width: 11%;
}
#child_care_registrants_table thead tr th:nth-child(7) {
	width: 11%;
}
#child_care_registrants_table thead tr th:nth-child(8) {
	width: 8%;
}
#child_care_registrants_table tbody tr td:nth-child(5) .form-control {
	text-align: right;
}
#child_care_registrants_table .input-group {
	margin: 0;
}

tbody tr td:last-child .fa-remove {
	cursor: pointer;
}

.input-group.date .input-group-append .input-group-text {
	background-color: #0055B8;
}
.input-group.date .input-group-append .input-group-text i {
	color: #fff;
}
.input-group.date {
	position: relative;
}
.bootstrap-datetimepicker-widget {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 999999;
}

.input-left-label {
	padding: 0.5rem 1rem;
	color: white;
	background-color: $primary;
}

.special-content .float-right {
	margin: 0 0 2.5rem 1.5rem;
}

@media screen and (max-width: 567px) {
	.special-content .float-right {
		display: none;
	}
}
@media screen and (min-width: 568px) {
	.special-content .float-right {
		max-width: 35%;
		display: block;
	}
}

.special-content h2, .special-content h3, .special-content h4 {
	text-align: left;
	margin-top: 1.5rem;
}
.special-content h2:first-of-type {
	margin-top: 0;
}

.special-content h3 {
	font-weight: 700;
}
// The following is necessary to cause embedded videos to honor the floated right content
.special-content div:not([class]) {
	display: flex;
}

h4 {
	font-size: 1.2rem;
}

.block-image_link p {
	text-align: center;
}
.block-image_link_2_cols .gallery-item > img {
	width: auto !important;
}

.block-one_column h2:only-child {
	text-align: center;
}

.block-iframe_block {
	text-align: center;
}

.post-meta>span {
	margin-left: 0;
}
.blog-card .post-title::after {
	left: 0;
}

.social-btn {
	margin-left: 0.625rem !important;
	margin-right: 0 !important;
}